import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Fab,
  FormControlLabel,
  Grid,
  IconButton,
  LinearProgress,
  MenuItem,
  Radio,
  RadioGroup,
  Select as MUISelect,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import {
  AddCircleOutline,
  GetApp as LoadTemplateIcon,
  ExpandLess,
  ExpandMore,
  List,
  Warning,
  Close,
  Refresh,
  ClearAll,
} from "@material-ui/icons";
import * as Yup from "yup";
import qs from "query-string";
import { FieldArray, FormikProvider, useFormik } from "formik";
import { useHistory } from "react-router-dom";
import {
  Order_By,
  useAllEnginesQuery,
  useProjectTemplatesQuery,
  useProjectEngineRatioMetricsQuery,
  useTemplateQuery,
  Performance_Report_Templates as Report_Templates,
  Performance_Report_Sections_Insert_Input as Report_Sections_Insert_Input,
  useProjectTaskFieldOptionsQuery,
  useCreateTemplateMutation,
  useUpdateTemplateMutation,
  useAllTemplatesQuery,
  Roles_Enum,
  useProjectBenchmarkTasksLazyQuery,
  useProjectCollectionTasksLazyQuery,
  Performance_Report_Pivot_Types_Enum as Pivot_Types_Enum,
} from "src/generated/asgard/graphql";
import {
  uuidArrToPostgresLiteral,
  md,
  usePagination,
} from "src/resources/Utils";
import { AllFieldsTable, TaskLink } from "src/resources/CollectionPlan";
import { ClosableDrawer, GridBreakpoints, InfoTooltip } from "src/Layout";
import {
  DrawerButton,
  pathProxy,
  TextField,
  Select,
  Switch,
  TextFieldWithOptions,
} from "src/components/Form";
import { HypertaskLink } from "src/resources/CollectionPlan";
import { EngineList } from "src/resources/Engine";
import { AllRatioMetricsTable } from "src/resources/Performance";
import { AllTemplatesTable } from "src/resources/Report";
import { useNotification } from "src/Notification";
import { useAuth, useRequireRole } from "src/auth";
import { ExcelExportButton } from "./ExcelReport";
import {
  makePivotGroups,
  PivotGroup,
  TaskFieldOptionItem,
  TaskItem,
  TaskSetItem,
} from "./utils";

export interface MetricResultType {
  project_id: string;
  metric_id: string;
  engine_id: string;
  score?: number | null;
  num_samples?: number | null;
  num_recordings?: number | null;
  numerator?: number | null;
  denominator?: number | null;
  ratio_metric?: {
    name: string | null;
    display_as_percentage: boolean | null;
    numerator_unit: string | null;
    denominator_unit?: string | null;
    larger_is_better: boolean | null;
    warning_limit?: number | null;
    critical_limit?: number | null;
  } | null;
  task_id?: string | null;
}

const StyledChip = withStyles({
  root: {
    marginRight: 4,
    marginBottom: 4,
  },
})(Chip);

const FloatingActionButton = (props: {
  disabled?: boolean;
  onClick: () => void;
  tooltip?: React.ReactNode;
  children: NonNullable<React.ReactNode>;
}) => {
  const fab = (
    <Fab
      size="large"
      color="primary"
      variant="extended"
      style={{ marginLeft: 4 }}
      disabled={!!props.disabled}
      onClick={props.onClick}
    >
      {props.children}
    </Fab>
  );
  return props.tooltip ? (
    <Tooltip title={props.tooltip}>
      <span>{fab}</span>
    </Tooltip>
  ) : (
    fab
  );
};

const FloatingActionGroup = (props: { children: React.ReactNode[] }) => {
  return (
    <div
      style={{
        position: "fixed",
        top: "auto",
        right: 30,
        bottom: 30,
        left: "auto",
      }}
    >
      {props.children}
    </div>
  );
};

export type ProjectPerformanceReportProps = {
  projectId: string;
  deliverableId: string;
};

/*******************************************************************************
 * The container object for a "performance report" component, providing
 * controls for selecting pre-made templates.
 ******************************************************************************/
export const ProjectPerformanceReport = (
  props: ProjectPerformanceReportProps,
) => {
  const numDefaultEngines = 1;
  // Grab engine id(s) and/or template id from query params in URL.
  const hist = useHistory();
  const q = qs.parse(hist.location.search, {
    arrayFormat: "comma",
  });
  const sep = ",";
  const selectedEngineIds: string[] = useMemo(
    () =>
      !q.engines ? [] : Array.isArray(q.engines) ? q.engines : [q.engines],
    [q],
  );
  const selectedTemplateId: string | null = useMemo(() => {
    if (!q.template) return null;
    if (!Array.isArray(q.template)) return q.template;
    if (q.template.length > 0) return q.template[0];
    return null;
  }, [q]);

  // Make methods for manipulating the url to manage user selections for
  // engines/template.
  const addEngines = useCallback(
    (ids: string[]) => {
      const { engines } = q;
      if (!!engines?.length) {
        hist.replace(
          "?" + qs.stringify({ ...q, engines: [engines, ...ids].join(sep) }),
        );
      } else {
        hist.replace("?" + qs.stringify({ ...q, engines: ids.join(sep) }));
      }
    },
    [hist, q],
  );
  const removeEngine = useCallback(
    (id: string) => {
      const { engines, ...rest } = q;
      const ids = selectedEngineIds.filter((eid) => eid !== id);
      if (ids.length > 0) {
        hist.replace("?" + qs.stringify({ ...q, engines: ids.join(sep) }));
      } else {
        hist.replace("?" + qs.stringify({ ...rest }));
      }
    },
    [hist, q, selectedEngineIds],
  );
  const setTemplate = useCallback(
    (id: string | null) => {
      const { template, ...rest } = q;
      if (id === null) {
        hist.replace("?" + qs.stringify({ ...rest }, { arrayFormat: "comma" }));
      } else {
        hist.replace(
          "?" +
            qs.stringify({ ...rest, template: id }, { arrayFormat: "comma" }),
        );
      }
    },
    [hist, q],
  );

  // Load a default engine if possible.
  const projectEnginesQueryFilter = {
    _and: [
      {
        engine_opmodes: {
          opmode: {
            opmode_projects: { project_id: { _eq: props.projectId } },
          },
        },
      },
      { ratio_metric_contributions: {} },
    ],
  };
  const defaultEnginesQuery = useAllEnginesQuery({
    variables: {
      where: projectEnginesQueryFilter,
      limit: numDefaultEngines,
      order_by: { created_at: Order_By.Desc },
    },
    skip: selectedEngineIds.length > 0,
  });
  const defaultEngineIds: string[] = useMemo(
    () => defaultEnginesQuery.data?.engines?.map((e) => e.id) ?? [],
    [defaultEnginesQuery],
  );
  const [defaultEnginesLoaded, setDefaultEnginesLoaded] = useState(
    selectedEngineIds.length > 0,
  );
  useEffect(() => {
    if (
      !defaultEnginesLoaded &&
      defaultEngineIds.length > 0 &&
      selectedEngineIds.length === 0
    ) {
      // Engines are ordered, so we can safely slice from the beginning of
      // the array.
      addEngines(defaultEngineIds);
      setDefaultEnginesLoaded(true);
    }
  }, [defaultEnginesLoaded, defaultEngineIds, selectedEngineIds, addEngines]);

  // Load a default template if possible.
  const projectTemplatesQuery = useProjectTemplatesQuery({
    variables: { project_id: props.projectId },
  });
  const defaultTemplateIds = useMemo(
    () =>
      projectTemplatesQuery.data?.project?.deliverable?.deliverable_templates?.map(
        (dt) => dt.template.id,
      ) ?? [],
    [projectTemplatesQuery],
  );
  const [defaultTemplateLoaded, setDefaultTemplateLoaded] = useState(
    selectedTemplateId !== null,
  );
  useEffect(() => {
    if (
      !defaultTemplateLoaded &&
      defaultTemplateIds.length > 0 &&
      selectedTemplateId === null &&
      // Don't load template until engines are loaded. Otherwise, both may try
      // and change the URL simultaneously.
      defaultEnginesLoaded
    ) {
      // Templates are ordered by "priority", so we can take the first one.
      setTemplate(defaultTemplateIds[0]);
      setDefaultTemplateLoaded(true);
    }
  }, [
    defaultEnginesLoaded,
    defaultTemplateLoaded,
    defaultTemplateIds,
    selectedTemplateId,
    setTemplate,
  ]);
  const loading = defaultEnginesQuery.loading || projectTemplatesQuery.loading;
  return loading ? (
    <Box>
      <LinearProgress style={{ marginTop: 20 }} />
    </Box>
  ) : (
    <Grid container spacing={3}>
      <Grid container item spacing={2}>
        <DrawerButton
          bp={{ xs: 3 }}
          variant="contained"
          color="primary"
          label="Add Engine"
          startIcon={<AddCircleOutline />}
          disableElevation
          fullWidth={true}
          content={(close) => (
            <EngineList
              where={[
                { id: { _nin: selectedEngineIds } },
                projectEnginesQueryFilter,
              ]}
              selectable="single"
              onSelect={(engine) => {
                if (engine?.id) addEngines([engine.id]);
                close();
              }}
            />
          )}
        />
        <DrawerButton
          bp={{ xs: 3 }}
          variant="contained"
          color="primary"
          label="Load Template"
          startIcon={<LoadTemplateIcon />}
          disableElevation
          fullWidth={true}
          content={(close) => (
            <AllTemplatesTable
              where={[
                {
                  deliverable_templates: {
                    deliverable: {
                      projects: { id: { _eq: props.projectId } },
                    },
                  },
                },
              ]}
              selectable="single"
              onSelect={(template) => {
                if (template?.id) setTemplate(template.id);
                close();
              }}
            />
          )}
        />
        <Grid item xs={3}>
          <Button
            fullWidth
            startIcon={<ClearAll />}
            variant="contained"
            color="primary"
            onClick={() => setTemplate(null)}
          >
            Clear Template
          </Button>
        </Grid>
        <Grid item xs={1}>
          <InfoTooltip
            size="large"
            content={
              <Typography variant="body2">
                If you cannot find any data on this page, it is likely that
                there are no <b>Tasks</b> associated with this project. This
                page uses the project-task relationship to help project
                stakeholders control which data are relevant to display.
                <br />
                <br />
                This page should load an engine automatically. If no engine is
                loaded, there is probably no performance data for this project.
              </Typography>
            }
          />
        </Grid>
      </Grid>
      <PerformanceReport
        projectId={props.projectId}
        deliverableId={props.deliverableId}
        templateId={selectedTemplateId}
        engineIds={selectedEngineIds}
        onRemoveEngine={removeEngine}
        onTemplateChange={(id) => setTemplate(id)}
      />
    </Grid>
  );
};

/**
 * Define the performance report template form.
 */

const ratioMetricSchema = Yup.object({
  id: Yup.string().required(),
  name: Yup.string().required(),
  description: Yup.string(),
  display_as_percentage: Yup.boolean(),
  numerator_unit: Yup.string().nullable(),
  denominator_unit: Yup.string().nullable(),
  larger_is_better: Yup.boolean().nullable(),
  warning_limit: Yup.number().nullable(),
  critical_limit: Yup.number().nullable(),
}).required();
const pivotFieldSchema = Yup.object({
  id: Yup.string().required(),
  name: Yup.string().required(),
  display: Yup.string().required(),
  description: Yup.string(),
}).required();
const sectionSchema = Yup.object({
  title: Yup.string().required(),
  include_missing_pivot_values: Yup.boolean().required(),
  pivot_type_id: Yup.mixed<Pivot_Types_Enum>()
    .oneOf(Object.values(Pivot_Types_Enum))
    .required(),

  ratioMetrics: Yup.array(ratioMetricSchema).required(
    "Each section must have at least one metric.",
  ),
  pivotFields: Yup.array(pivotFieldSchema),
}).required();
const templateSchema = Yup.object({
  id: Yup.string().min(1).nullable(true),
  overwrite: Yup.bool().required(),
  justCreated: Yup.bool().required(),
  createdBy: Yup.object({
    id: Yup.string().nullable(),
    name: Yup.string().nullable(),
  }).required(),
  columns: Yup.object({
    name: Yup.string().required(),
    description: Yup.string(),
  }).required(),
  sections: Yup.array(sectionSchema).required(),
}).required();

type TemplateSchema = Yup.InferType<typeof templateSchema>;
export type SectionSchema = Yup.InferType<typeof sectionSchema>;
type RatioMetricSchema = Yup.InferType<typeof ratioMetricSchema>;
export type PivotFieldSchema = Yup.InferType<typeof pivotFieldSchema>;

/**
 * Use to transform the template object returned from a query into an object
 * that can populate the template form.
 */
function transformTemplate(
  template: DeepPartial<Report_Templates>,
): TemplateSchema {
  return {
    // The "id" should always be there. If it is not, then use an empty string,
    // since this will be invalid and prevent form submission.
    id: template.id ?? "",
    overwrite: true,
    justCreated: false,
    createdBy: {
      id: template.user?.id ?? "",
      name: template.user?.name ?? "",
    },
    columns: {
      name: template.name ?? "",
      description: template.description ?? "",
    },
    sections:
      template.sections?.map((section) => ({
        title: section.title ?? "",
        include_missing_pivot_values:
          section.include_missing_pivot_values ?? false,
        pivot_type_id: section.pivot_type_id ?? Pivot_Types_Enum.Fields,
        ratioMetrics:
          section.section_ratio_metrics?.map((metric) => ({
            id: metric.ratio_metric?.id ?? "",
            name: metric.ratio_metric?.name ?? "",
            description: metric.ratio_metric?.description ?? "",
            display_as_percentage:
              metric.ratio_metric?.display_as_percentage ?? true,
            larger_is_better: metric.ratio_metric?.larger_is_better ?? false,
            numerator_unit: metric.ratio_metric?.numerator_unit,
            denominator_unit: metric.ratio_metric?.denominator_unit,
            warning_limit:
              typeof metric.ratio_metric?.warning_limit === "number"
                ? metric.ratio_metric.warning_limit
                : null,
            critical_limit:
              typeof metric.ratio_metric?.critical_limit === "number"
                ? metric.ratio_metric.critical_limit
                : null,
          })) ?? [],
        pivotFields:
          section.pivot_fields?.map((pf) => ({
            id: pf.field?.id ?? "",
            name: pf.field?.name ?? "",
            display: pf.field?.display ?? "",
            description: pf.field?.name ?? "",
          })) ?? [],
      })) ?? [],
  };
}

/**
 * Certain values need to be available to components deep into the component
 * tree, based on selections made at the top level "performance report"
 * component, namely to query for the individual "scores".
 */
type PerformanceReportContextProps = {
  /** Used to narrow result of "score" queries */
  projectId: string;
  /** Used to render the proper number of columns */
  engines: { id: string; label: string }[];
  /** Used to narrow result of "score" queries, namely to switch between "collection" and "benchmark" tasks */
  taskSet: TaskSetItem[];
  /** Used for constructing "pivot groups", contains a mapping of field-options to all possible tasks assigned to the given project */
  taskFieldOptions: TaskFieldOptionItem[];
};
export const PerformanceReportContext =
  createContext<PerformanceReportContextProps>({
    projectId: "",
    engines: [],
    taskSet: [],
    taskFieldOptions: [],
  });

/*******************************************************************************
 * A complete "performance report", the components of which are tied to a form,
 * allowing the user to edit the indicated template, or create a new one.
 ******************************************************************************/
const PerformanceReport = (props: {
  projectId: string;
  engineIds: string[];
  templateId: string | null;
  deliverableId: string;
  /**
   * Note: `onTemplateChange` callback should result in a changing of the value
   * passed to the `templateId` prop, which will in turn trigger a re-render of
   * the form, which is necessary to hydrate the form with the correct
   * values for updating the new template.
   **/
  onTemplateChange: (id: string | null) => void;
  onRemoveEngine: (id: string) => void;
}) => {
  type TaskQueryType = "benchmark" | "collection";
  const [taskQueryType, setTaskQueryType] =
    useState<TaskQueryType>("collection");

  const { onRemoveEngine, templateId, deliverableId, projectId, engineIds } =
    props;
  const auth = useAuth();
  const currentUser = {
    id: auth.user.id.toLowerCase(),
    name: auth.user.name ?? "",
  };
  const ag = useRequireRole([Roles_Enum.Asgard]);
  const [createTemplate] = useCreateTemplateMutation();
  const [updateTemplate] = useUpdateTemplateMutation();
  const engineQuery = useAllEnginesQuery({
    variables: { where: { id: { _in: engineIds } } },
    skip: engineIds.length < 1,
  });
  const selectedEngines = engineQuery.data?.engines ?? [];

  const [trainingTaskQuery, trainingTasks] = useProjectCollectionTasksLazyQuery(
    {
      variables: { project_id: projectId },
    },
  );
  const [benchmarkTaskQuery, benchmarkTasks] =
    useProjectBenchmarkTasksLazyQuery({
      variables: { project_id: projectId },
    });
  const [taskSet, setTaskSet] = useState<null | TaskSetItem[]>(null);

  // Gather all possible task-field-option combinations.
  const taskFieldOptionQuery = useProjectTaskFieldOptionsQuery({
    variables: { project_id: projectId },
    skip: !taskSet?.length,
  });
  const taskFieldOptions: TaskFieldOptionItem[] = useMemo(() => {
    // If either the task set or query is empty, don't bother iterating.
    if (!taskFieldOptionQuery.data?.tasks?.length || !taskSet?.length)
      return [];
    const taskSetIds = (taskSet ?? []).map(({ id }) => id);
    const acc: TaskFieldOptionItem[] = [];
    taskFieldOptionQuery.data.tasks
      .filter((task) => !!task && taskSetIds.includes(task.id))
      .forEach((task) => {
        const taskItem: TaskItem = {
          id: task.id,
          number: `${task.legacy_template_id}`,
        };
        const hypertasks: TaskItem[] = task.hypertask_tasks.map((ht) => ({
          id: ht.hypertask.id,
          number: `${ht.hypertask.number}`,
        }));
        (task.task_field_options ?? []).forEach((tfo) => {
          const match = acc.find(
            ({ field, option }) =>
              !!option?.id &&
              field.id === tfo.field_option?.field?.id &&
              option.id === tfo.field_option?.option?.id,
          );
          if (match === undefined) {
            acc.push({
              field: {
                id: tfo.field_option?.field?.id ?? "",
                display: tfo.field_option?.field?.name ?? "",
              },
              option: {
                id: tfo.field_option?.option?.id ?? "",
                display: tfo.field_option?.option?.display ?? "",
              },
              tasks: [taskItem],
              hypertasks,
            });
          } else {
            match.tasks.push(taskItem);
          }
        });
      });
    return acc;
  }, [taskFieldOptionQuery, taskSet]);

  useEffect(() => {
    switch (taskQueryType) {
      case "collection":
        if (trainingTasks.loading) {
          return;
        } else if (!trainingTasks.called) {
          trainingTaskQuery();
          setTaskSet(null);
        } else if (taskSet === null) {
          setTaskSet(
            trainingTasks.data?.tasks?.map((task) => ({
              id: task.id,
              number: `${task.legacy_template_id}`,
              hypertasks: task.hypertask_tasks.map((ht) => ({
                id: ht.hypertask.id,
                number: `${ht.hypertask.number}`,
              })),
            })) ?? [],
          );
        }
        break;
      case "benchmark":
        if (benchmarkTasks.loading) {
          return;
        } else if (!benchmarkTasks.called) {
          benchmarkTaskQuery();
          setTaskSet(null);
        } else if (taskSet === null) {
          setTaskSet(
            (benchmarkTasks.data?.tasks ?? []).map((task) => ({
              id: task.id,
              number: `${task.legacy_template_id}`,
              hypertasks: [], // Benchmarking does not use hypertasks.
            })),
          );
        }
        break;
    }
  }, [
    taskQueryType,
    taskSet,
    trainingTasks,
    trainingTaskQuery,
    benchmarkTasks,
    benchmarkTaskQuery,
  ]);

  const allTemplatesQuery = useAllTemplatesQuery();
  const allTemplateNames: string[] =
    allTemplatesQuery.data?.templates?.map((t) => t.name ?? "") ?? [];
  const templateInitialValues = {
    id: null,
    overwrite: false,
    justCreated: false,
    createdBy: {
      // Authorship not established until insert.
      id: null,
      name: null,
    },
    columns: {
      name: "",
      description: "",
    },
    sections: [],
  };

  const formik = useFormik<TemplateSchema>({
    validationSchema: templateSchema,
    // This form can get quite large. Only validate on submit.
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
    validate: (values) => {
      if (!values.overwrite && allTemplateNames.includes(values.columns.name)) {
        return {
          columns: {
            name: `template already exists with name ${values.columns.name}`,
          },
        };
      }
    },
    initialValues: templateInitialValues,
    onSubmit: async (values, helpers) => {
      /**
       * The "id" and "overwrite" fields in the form will determine whether to
       * use the "update" or "insert" mutation.
       *
       * If the "id" is null, the "overwrite" field should always be false,
       * since there is nothing to overwrite. However, when the "id" is not
       * null, the user may either overwrite, or create a new template.
       *
       * The "overwrite" field gets set/reset upon the opening/closing of a
       * dialog component. See below.
       */
      const { id, overwrite, columns, sections } = values;
      const overwriting = overwrite && id !== null;
      const sectionsInput: Report_Sections_Insert_Input[] = sections.map(
        ({ ratioMetrics, pivotFields, ...cols }, i) => {
          const c = overwriting ? { ...cols, template_id: id } : { ...cols };
          return {
            position: i,
            ...c,
            section_ratio_metrics: {
              data: ratioMetrics.map((rm, j) => ({
                position: j,
                ratio_metric_id: rm.id,
              })),
            },
            pivot_fields: {
              data:
                pivotFields?.map((pf, k) => ({
                  position: k,
                  field_id: pf.id,
                })) ?? [],
            },
          };
        },
      );
      if (overwriting) {
        await (async () => {
          return await updateTemplate({
            variables: {
              template_id: id!,
              columns: { ...columns, created_by: currentUser.id },
              sections: sectionsInput,
            },
          });
        })();
      } else {
        const createResult = await (async () => {
          return await createTemplate({
            variables: {
              input: {
                ...columns,
                created_by: currentUser.id,
                sections: {
                  data: sectionsInput,
                },
                deliverable_templates: {
                  data: [{ deliverable_id: deliverableId, priority: 0 }],
                },
              },
            },
          });
        })();
        const newId = createResult.data?.template?.id ?? null;
        /**
         * The "justCreated" field is set to true to signal that the change in
         * id should not precipitate a re-render of the form.
         *
         * Since `setValues` and `onTemplateChange` do not result in a
         * simultaneous change to both the `templateId` property (controlled by
         * the parent component) and the "id" field in the form, this will
         * appear as if the parent component is trying to change the template.
         *
         * So long as this value is true, we will not be allowed to query for a
         * different template, so this value should be reset to false as soon as
         * the `templateId` and "id" field are in sync.
         */
        helpers.setValues(
          {
            ...values,
            id: newId,
            columns: { ...values.columns },
            createdBy: { ...currentUser },
            overwrite: true,
            justCreated: true,
          },
          false,
        );
        props.onTemplateChange(newId);
      }
    },
  });
  const { values, errors, setValues, setFieldValue, resetForm } = formik;
  const path = pathProxy<typeof values>();

  const justCreated = values.justCreated;
  const loadedTemplateId = values.id;
  // If the template id property matches the id in the form, then skip querying
  // and hydrating the form.
  const urlFormMismatch = useMemo(
    () => !!loadedTemplateId && loadedTemplateId === templateId,
    [templateId, loadedTemplateId],
  );
  const templateQuery = useTemplateQuery({
    variables: { id: templateId ?? "" },
    skip: !templateId || justCreated || urlFormMismatch,
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    if (loadedTemplateId !== templateId) {
      const queriedTemplate = templateQuery.data?.template;
      if (templateId === null) {
        resetForm();
      } else if (!!queriedTemplate) {
        setValues(transformTemplate(queriedTemplate));
      }
    }
  }, [templateId, templateQuery, setValues, resetForm, loadedTemplateId]);
  useEffect(() => {
    if (justCreated && !urlFormMismatch) {
      setFieldValue("justCreated", false);
    }
  }, [justCreated, urlFormMismatch, setFieldValue]);

  // Because a) the form only validates on submit, and b) there are many
  // invisible fields, make sure to create a notification to inform the user
  // of any errors in the form.
  const notification = useNotification();
  const notifyErrors = useCallback(() => {
    if (Object.keys(errors).length) {
      notification.create({
        severity: "error",
        title: `Form Error`,
        body: md()
          .syntax("json", JSON.stringify({ errors, values }, null, 2))
          .get(),
      });
    }
  }, [values, errors, notification]);

  /**
   * When saving a template, a dialog will open allowing the user to set the
   * "name" and "description" fields.
   *
   * However, depending on whether the user is using the "save as" or
   * "overwrite" options, the "overwrite" field may need to be changed upon
   * opening/closing the dialog.
   */
  const [dialogOpen, setDialogOpen] = useState(false);
  const handleOverwriteDialogOpen = () => {
    // Note: the "overwrite" button should be disabled if the "overwrite" field
    // is false.
    setDialogOpen(true);
  };
  const handleSaveAsDialogOpen = () => {
    setFieldValue("overwrite", false);
    setDialogOpen(true);
  };
  const handleDialogClose = () => {
    setFieldValue("overwrite", !!values.id);
    setDialogOpen(false);
  };

  // Do not allow users to overwrite other user's templates.
  const madeByDifferentUser =
    values.createdBy.id !== null && values.createdBy.id !== currentUser.id;

  return (
    <PerformanceReportContext.Provider
      value={{
        projectId,
        taskFieldOptions,
        taskSet: taskSet ?? [],
        engines: selectedEngines.map((engine) => ({
          id: engine.id,
          label: engine.build_number ?? "",
        })),
      }}
    >
      <FormikProvider value={formik}>
        <Box marginLeft={3}>
          <Typography variant="h4">{values.columns.name}</Typography>
          {values.createdBy.name && (
            <Typography variant="body1">by {values.createdBy.name}</Typography>
          )}
          {values.columns.description && (
            <Typography variant="caption">
              {values.columns.description}
            </Typography>
          )}
        </Box>
        <Box marginLeft={3}>
          <RadioGroup
            row
            value={taskQueryType}
            onChange={(e) => {
              setTaskQueryType(e.target.value as TaskQueryType);
              // Need to null this so that effect will re-fire.
              setTaskSet(null);
            }}
          >
            <FormControlLabel
              value={"collection"}
              control={<Radio />}
              label="View Collection Tasks"
            />
            <FormControlLabel
              value={"benchmark"}
              control={<Radio />}
              label="View Benchmark Tasks"
            />
          </RadioGroup>
        </Box>
        <div>
          <ExcelExportButton
            projectId={projectId}
            sections={formik.values.sections}
            engines={selectedEngines.map((engine) => ({
              id: engine.id,
              label: engine.build_number ?? "",
            }))}
            taskFieldOptions={taskFieldOptions}
          />
        </div>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {/**
                 * Table has 3 + N columns, where N is the number of engines
                 * selected:
                 * - 1 column for expand/collapse controls
                 * - 1 column for group/task display
                 * - N columns for data for group/engine
                 * - 1 column for delete controls
                 **/}
                <TableCell rowSpan={2} style={{ width: 50 }} />
                <TableCell rowSpan={2} />
                <TableCell colSpan={selectedEngines.length}>
                  {selectedEngines.length > 0
                    ? "Engines"
                    : "No engines selected"}
                </TableCell>
                <TableCell rowSpan={2} style={{ width: 50 }} />
              </TableRow>
              <TableRow>
                {selectedEngines.map((engine) => (
                  <TableCell key={engine.id}>
                    <Tooltip
                      title={
                        <Typography variant="body2">
                          <b>Build Version</b> {engine.build_version}
                          <br />
                          <b>Build Number</b> {engine.build_number ?? ""}
                          <br />
                          <b>Azure Build Ref</b> {engine.azure_build_ref ?? ""}
                          <br />
                          <b>Azure Release Ref</b>{" "}
                          {engine.azure_release_ref ?? ""}
                          <br />
                          <b>Build Definition ID</b>{" "}
                          {engine.build_definition_id_azure ?? ""}
                          <br />
                          <b>Release Definition ID</b>{" "}
                          {engine.release_definition_id_azure ?? ""}
                          <br />
                          <b>Nickname</b> {engine.nickname ?? ""}
                          <br />
                          {ag && (
                            <>
                              <b>Branch</b> {engine.branch ?? ""}
                              <br />
                            </>
                          )}
                          <b>Type</b> {engine.type ?? ""}
                          <br />
                          <b>Commit Hash</b>{" "}
                          {engine.elliptic_engine_commit_hash ?? ""}
                          <br />
                        </Typography>
                      }
                    >
                      <Chip
                        color="primary"
                        onDelete={() => onRemoveEngine(engine.id)}
                        label={engine.build_number ?? ""}
                      />
                    </Tooltip>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <FieldArray
              name={path.sections!._}
              render={(helper) => (
                <TableBody>
                  {formik.values.sections.map((section, i) => (
                    <Section
                      key={i}
                      path={path.sections[i]._}
                      section={section}
                      onDelete={() => helper.remove(i)}
                    />
                  ))}
                  <TableRow>
                    <TableCell colSpan={selectedEngines.length + 3}>
                      <Button
                        color="primary"
                        variant="text"
                        startIcon={<AddCircleOutline />}
                        onClick={() =>
                          helper.push({
                            title: "New Section",
                            include_missing_pivot_values: false,
                            pivot_type_id: Pivot_Types_Enum.Fields,
                            pivotFields: [],
                            ratioMetrics: [],
                          })
                        }
                      >
                        Add Section
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            />
          </Table>
        </TableContainer>
        <Grid item xs={3}></Grid>
        <FloatingActionGroup>
          <FloatingActionButton
            disabled={formik.isSubmitting}
            onClick={() => formik.resetForm()}
          >
            <Refresh />
          </FloatingActionButton>
          <FloatingActionButton
            tooltip="overwrites the current template"
            disabled={
              formik.isSubmitting || !values.overwrite || madeByDifferentUser
            }
            onClick={handleOverwriteDialogOpen}
          >
            Update
          </FloatingActionButton>
          <FloatingActionButton
            tooltip="creates a new template"
            disabled={formik.isSubmitting}
            onClick={handleSaveAsDialogOpen}
          >
            Save As
          </FloatingActionButton>
        </FloatingActionGroup>
        <Dialog open={dialogOpen} onClose={handleDialogClose}>
          <DialogContent>
            <Grid container spacing={2}>
              <TextFieldWithOptions
                bp={{ xs: 12 }}
                name={path?.columns?.name!._ ?? "name"}
                label="Name"
                options={allTemplateNames}
              />
              <TextField
                bp={{ xs: 12 }}
                name={path?.columns?.description?._ ?? "description"}
                label="Description"
              />
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              disableElevation
              onClick={handleDialogClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              disabled={formik.isSubmitting}
              onClick={() => {
                formik.handleSubmit();
                notifyErrors();
                setDialogOpen(false);
              }}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </FormikProvider>
    </PerformanceReportContext.Provider>
  );
};

/**
 * Each section needs the complete set of project tasks, and the pivot groups
 * constructed from the field-options present in the project collection plan,
 * passed deep into each section's component tree.
 */
const SectionContext = createContext<{
  pivotGroups: PivotGroup[];
}>({
  pivotGroups: [],
});

/*******************************************************************************
 * A fragment containing rows for the "performance report" table, which include
 * controls for customizing the section.
 ******************************************************************************/
const Section = (props: {
  path: string;
  section: SectionSchema;
  onDelete: () => void;
}) => {
  const { engines, taskSet, taskFieldOptions } = useContext(
    PerformanceReportContext,
  );
  const { section } = props;
  const includeMissingFields = section.include_missing_pivot_values;
  const [expanded, setExpanded] = useState(true);
  const allPivotTypes = Object.values(Pivot_Types_Enum);

  const pivotFields = useMemo(() => section.pivotFields ?? [], [section]);
  const pivotGroups = useMemo(
    () =>
      makePivotGroups(
        pivotFields,
        taskFieldOptions,
        includeMissingFields,
        taskSet,
      ),
    [pivotFields, taskFieldOptions, includeMissingFields, taskSet],
  );

  return (
    <SectionContext.Provider value={{ pivotGroups }}>
      <TableRow style={{ backgroundColor: "#EEEEEE" }}>
        <TableCell>
          <IconButton onClick={() => setExpanded((current) => !current)}>
            {expanded ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </TableCell>
        <TableCell colSpan={engines.length + 1}>
          <Grid container spacing={1}>
            <TextField
              bp={{ xs: 12 }}
              fullWidth
              label="Section"
              name={`${props.path}.title`}
            />
            {expanded && (
              <>
                <Select
                  bp={{ xs: 2 }}
                  fullWidth
                  name={`${props.path}.pivot_type_id`}
                  label="pivot on"
                >
                  {allPivotTypes.map((pivotType) => (
                    <MenuItem key={pivotType} value={pivotType}>
                      {pivotType}
                    </MenuItem>
                  ))}
                </Select>
                <Grid item xs={10} />
                {props.section.pivot_type_id === Pivot_Types_Enum.Fields && (
                  <>
                    <PivotFieldSelector
                      bp={{ xs: 8 }}
                      path={`${props.path}.pivotFields`}
                      pivotFields={props.section.pivotFields ?? []}
                    />
                    <Switch
                      bp={{ xs: 4 }}
                      name={`${props.path}.include_missing_pivot_values`}
                      label="Include Tasks Without Pivot Fields"
                    />
                  </>
                )}
              </>
            )}
          </Grid>
        </TableCell>
        <TableCell>
          <IconButton onClick={() => props.onDelete()}>
            <Close />
          </IconButton>
        </TableCell>
      </TableRow>
      {expanded && (
        <>
          <TableRow>
            <TableCell colSpan={2} />
            {engines.map(({ id, label }) => (
              <TableCell key={id}>{label}</TableCell>
            ))}
            <TableCell />
          </TableRow>
          <FieldArray
            name={`${props.path}.ratioMetrics`}
            render={(helper) => (
              <>
                {props.section.ratioMetrics.map((m, i) => (
                  <MetricRows
                    key={i}
                    ratioMetric={m}
                    pivotType={props.section.pivot_type_id}
                    onDelete={() => helper.remove(i)}
                  />
                ))}
                <TableRow>
                  <TableCell />
                  <TableCell colSpan={engines.length + 2}>
                    <DrawerButton
                      variant="text"
                      color="primary"
                      label="Add Metric"
                      startIcon={<AddCircleOutline />}
                      disableElevation
                      content={(close) => (
                        <AllRatioMetricsTable
                          selectable="single"
                          onSelect={(item) => {
                            item.id &&
                              helper.push({
                                id: item.id,
                                name: item.name ?? "",
                                description: item.description ?? "",
                              });
                            close();
                          }}
                        />
                      )}
                    />
                  </TableCell>
                </TableRow>
              </>
            )}
          />
        </>
      )}
    </SectionContext.Provider>
  );
};

/*******************************************************************************
 * A component that allows the user to populate the "pivot field" list in a
 * "section" form.
 ******************************************************************************/
const PivotFieldSelector = (props: {
  bp: GridBreakpoints;
  path: string;
  pivotFields: PivotFieldSchema[];
}) => {
  const { projectId } = useContext(PerformanceReportContext);
  const [show, setShow] = useState(false);
  return (
    <FieldArray
      name={props.path}
      render={(helper) => (
        <>
          <Grid item {...props.bp}>
            {props.pivotFields.map((pf, i) => (
              <Tooltip title={`${pf.name}: ${pf.description}`} key={i}>
                <StyledChip
                  key={pf.id}
                  label={pf.display}
                  onDelete={() => helper.remove(i)}
                />
              </Tooltip>
            ))}
            <StyledChip
              color="secondary"
              icon={<AddCircleOutline />}
              label={"Add Pivot Field"}
              onClick={() => setShow(true)}
            />
          </Grid>
          <ClosableDrawer show={show} setShow={setShow}>
            <AllFieldsTable
              selectable="single"
              where={[
                {
                  field_options: {
                    task_field_options: {
                      task: {
                        _or: [
                          { project_tasks: { project_id: { _eq: projectId } } },
                          {
                            rabbit_test_tasks: {
                              plan: {
                                plan_projects: {
                                  project_id: { _eq: projectId },
                                },
                              },
                            },
                          },
                          {
                            hypertask_tasks: {
                              hypertask: {
                                project_hypertasks: {
                                  project_id: { _eq: projectId },
                                },
                              },
                            },
                          },
                        ],
                      },
                    },
                  },
                },
              ]}
              onSelect={(item) => {
                item.id &&
                  helper.push({
                    id: item.id,
                    name: item.name ?? "",
                    display: item.display ?? "",
                    description: item.description ?? "",
                  });
                setShow(false);
              }}
            />
          </ClosableDrawer>
        </>
      )}
    />
  );
};

type MetricRowsBaseProps = {
  ratioMetric: RatioMetricSchema;
};

/*******************************************************************************
 * A fragment containing all table rows for a single metric inside the
 * "performance report" table.
 ******************************************************************************/
const MetricRows = (
  props: MetricRowsBaseProps & {
    pivotType: Pivot_Types_Enum;
    onDelete: () => void;
  },
) => {
  const { engines, taskSet } = useContext(PerformanceReportContext);
  const [expanded, setExpanded] = useState(true);
  const metricRows =
    props.pivotType === Pivot_Types_Enum.Fields ? (
      <MetricRowsByPivotFieldGroups ratioMetric={props.ratioMetric} />
    ) : props.pivotType === Pivot_Types_Enum.Tasks ? (
      <MetricRowsByTask ratioMetric={props.ratioMetric} />
    ) : props.pivotType === Pivot_Types_Enum.Hypertasks ? (
      <MetricRowsByHypertask ratioMetric={props.ratioMetric} />
    ) : (
      <></>
    );

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton onClick={() => setExpanded((current) => !current)}>
            {expanded ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </TableCell>
        <TableCell>
          <Grid container spacing={1}>
            <Grid container item xs={12} direction="row">
              <Typography variant="body1" style={{ margin: 10 }}>
                {props.ratioMetric.name}
              </Typography>
              {(props.ratioMetric.description?.length ?? 0) > 0 && (
                <InfoTooltip content={<>{props.ratioMetric.description}</>} />
              )}
            </Grid>
          </Grid>
        </TableCell>
        {/* Show the overall score for this engine at the top. */}
        {engines.map((engine) => (
          <ScoreCell
            key={engine.id}
            engineId={engine.id}
            metricId={props.ratioMetric.id}
            taskIds={taskSet.map(({ id }) => id)}
          />
        ))}
        <TableCell>
          <IconButton onClick={() => props.onDelete()}>
            <Close />
          </IconButton>
        </TableCell>
      </TableRow>
      {expanded && metricRows}
    </>
  );
};

/*******************************************************************************
 * A fragment containing table rows to render inside the "performance report"
 * table, when pivoting on the individual tasks in a dataset.
 ******************************************************************************/
const MetricRowsByTask = (props: MetricRowsBaseProps) => {
  const { engines, taskSet } = useContext(PerformanceReportContext);

  // When a project has many tasks, too many queries get fired at once, causing
  // the page to time out.
  const pageLimits = [5, 10, 20];
  const [pageVars, pageController] = usePagination({ limit: pageLimits[0] });
  const total = taskSet.length;
  const { limit, offset } = pageVars;
  const { setLimit, setOffset } = pageController;
  useEffect(() => {
    if (!total || !offset || !setOffset) return;
    if (offset > total) setOffset(0);
  }, [total, offset, setOffset]);

  const pageTasks = taskSet.slice(offset, offset + limit);

  return (
    <>
      {pageTasks.map((task) => (
        <TableRow key={task.id}>
          <TableCell />
          <TableCell>
            <TaskLink openNewTab id={task.id} number={parseInt(task.number)} />
          </TableCell>
          {engines.map((engine) => (
            <ScoreCell
              key={`${engine.id}-${task.id}`}
              engineId={engine.id}
              metricId={props.ratioMetric.id}
              taskIds={[task.id]}
            />
          ))}
          <TableCell />
        </TableRow>
      ))}
      <TableRow>
        <TableCell />
        <TableCell>
          <Grid container justify="space-between" spacing={1}>
            <Grid item>
              <Pagination
                count={total ? Math.ceil(total / (limit ?? 0)) : 0}
                siblingCount={2}
                onChange={(_: unknown, newPage: number) => {
                  if (!limit || !setOffset) return;
                  setOffset((newPage - 1) * limit);
                }}
              />
            </Grid>
            <Grid item>
              <MUISelect
                value={limit}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                  if (!setLimit) return;
                  setLimit(parseInt(event.target.value as string, 10));
                }}
              >
                {pageLimits.map((n) => (
                  <MenuItem key={n} value={n}>
                    {n}
                  </MenuItem>
                ))}
              </MUISelect>
            </Grid>
          </Grid>
        </TableCell>
        <TableCell colSpan={engines.length + 1} />
      </TableRow>
    </>
  );
};

/*******************************************************************************
 * A fragment containing table rows to render inside the "performance report"
 * table, when pivoting on the individual tasks in a dataset.
 ******************************************************************************/
const MetricRowsByHypertask = (props: MetricRowsBaseProps) => {
  const { engines, taskSet } = useContext(PerformanceReportContext);

  // Create a mapping between hypertasks and tasks.
  type HypertaskMapItem = TaskItem & { tasks: TaskItem[] };
  const hypertaskSet: HypertaskMapItem[] = useMemo(
    () =>
      taskSet.reduce((acc, task) => {
        const out = [...acc];
        task.hypertasks.forEach((h) => {
          const match = out.find(({ id }) => id === h.id);
          if (!!match) {
            match.tasks.push(task);
          } else {
            out.push({ ...h, tasks: [task] });
          }
        });
        return out;
      }, [] as HypertaskMapItem[]),
    [taskSet],
  );

  // When a project has many tasks, too many queries get fired at once, causing
  // the page to time out.
  const pageLimits = [5, 10, 20];
  const [pageVars, pageController] = usePagination({ limit: pageLimits[0] });
  const total = hypertaskSet.length;
  const { limit, offset } = pageVars;
  const { setLimit, setOffset } = pageController;
  useEffect(() => {
    if (!total || !offset || !setOffset) return;
    if (offset > total) setOffset(0);
  }, [total, offset, setOffset]);

  const pageHypertasks = hypertaskSet.slice(offset, offset + limit);

  return (
    <>
      {pageHypertasks.map((hypertask) => (
        <TableRow key={hypertask.id}>
          <TableCell />
          <TableCell>
            <HypertaskLink
              openNewTab
              id={hypertask.id}
              number={parseInt(hypertask.number)}
            />
          </TableCell>
          {engines.map((engine) => (
            <ScoreCell
              key={`${engine.id}-${hypertask.id}`}
              engineId={engine.id}
              metricId={props.ratioMetric.id}
              taskIds={hypertask.tasks.map(({ id }) => id)}
            />
          ))}
          <TableCell />
        </TableRow>
      ))}
      <TableRow>
        <TableCell />
        <TableCell>
          <Grid container justify="space-between" spacing={1}>
            <Grid item>
              <Pagination
                count={total ? Math.ceil(total / (limit ?? 0)) : 0}
                siblingCount={2}
                onChange={(_: unknown, newPage: number) => {
                  if (!limit || !setOffset) return;
                  setOffset((newPage - 1) * limit);
                }}
              />
            </Grid>
            <Grid item>
              <MUISelect
                value={limit}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                  if (!setLimit) return;
                  setLimit(parseInt(event.target.value as string, 10));
                }}
              >
                {pageLimits.map((n) => (
                  <MenuItem key={n} value={n}>
                    {n}
                  </MenuItem>
                ))}
              </MUISelect>
            </Grid>
          </Grid>
        </TableCell>
        <TableCell colSpan={engines.length + 1} />
      </TableRow>
    </>
  );
};

/*******************************************************************************
 * A fragment containing table rows to render inside the "performance report"
 * table, when pivoting on fields in the data collection plan.
 ******************************************************************************/
const MetricRowsByPivotFieldGroups = (props: MetricRowsBaseProps) => {
  const { engines } = useContext(PerformanceReportContext);
  const { pivotGroups } = useContext(SectionContext);

  return (
    <>
      {pivotGroups.map((group, i) => (
        <TableRow key={i}>
          <TableCell />
          <TableCell>
            <GroupDisplay group={group} />
          </TableCell>
          {engines.map((engine, j) => (
            <ScoreCell
              key={`${engine.id}-${j}`}
              engineId={engine.id}
              metricId={props.ratioMetric.id}
              taskIds={group.tasks.map(({ id }) => id)}
            />
          ))}
        </TableRow>
      ))}
    </>
  );
};

/*******************************************************************************
 * A fragment of chips that represent the field-options that define a "pivot
 * group", along with a button that will open a list of all the tasks in the
 * dataset containing those field options.
 ******************************************************************************/
const GroupDisplay = (props: { group: PivotGroup }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      {props.group.fieldOptions
        .filter((fo) => !!fo)
        .map((fo) => (
          <Tooltip
            key={`${fo!.field.id}${fo!.option?.id}`}
            title={fo!.field.display}
          >
            <StyledChip label={fo!.option?.display} />
          </Tooltip>
        ))}
      <IconButton size="small" onClick={() => setOpen(true)}>
        <List />
      </IconButton>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogContent>
          <Typography variant="h6">Tasks</Typography>
          {props.group.tasks.map(({ id, number }) => (
            <TaskLink openNewTab key={id} id={id} number={parseInt(number)} />
          ))}
        </DialogContent>
      </Dialog>
    </>
  );
};

////////////////////////////////////////////////////////////////////////////////
const scoreColorScale: { background: string; text: string }[] = [
  { background: "#5ad0af", text: "#000000" },
  { background: "#86e5a4", text: "#000000" },
  { background: "#d0e18f", text: "#000000" },
  { background: "#eee5ae", text: "#000000" },
  { background: "#f7e8ae", text: "#000000" },
  { background: "#efc57e", text: "#000000" },
  { background: "#eba15d", text: "#000000" },
  { background: "#d34747", text: "#ffffff" },
  { background: "#a42642", text: "#ffffff" },
];

type ScoreCellProps = {
  engineId: string;
  metricId: string;
  taskIds: string[];
};

const ScoreCell = (props: ScoreCellProps) => {
  const { projectId } = useContext(PerformanceReportContext);

  const scoreQuery = useProjectEngineRatioMetricsQuery({
    variables: {
      metric_id: props.metricId,
      project_id: projectId,
      engine_ids: uuidArrToPostgresLiteral([props.engineId]),
      task_ids: uuidArrToPostgresLiteral(props.taskIds),
    },
  });

  const thisScore = scoreQuery.data?.project_engine_ratio_metrics?.find(
    (score) => score.engine_id === props.engineId,
  );

  if (!thisScore) {
    return <TableCell>No data</TableCell>;
  }

  const thisMetric = thisScore.ratio_metric;
  const datasetDisplay = thisMetric.sample_unit
    ? `${thisScore.num_samples} ${thisMetric.sample_unit}, ${thisScore.num_recordings} recordings`
    : `${thisScore.num_recordings} recordings`;

  if (thisScore.score === undefined || thisScore.score === null) {
    return (
      <TableCell>
        <Tooltip title={datasetDisplay}>
          <StyledChip label="Undefined (division by 0)" />
        </Tooltip>
      </TableCell>
    );
  }

  let valueDisplay = "";
  const numDecimalPlaces = 2;
  const multiplier = thisMetric.display_as_percentage
    ? 10 ** (numDecimalPlaces + 2)
    : 10 ** numDecimalPlaces;
  const rounded =
    Math.round(thisScore.score * multiplier) / 10 ** numDecimalPlaces;

  let colors = { background: "#FFFFFF", text: "#000000" };

  if (thisMetric.display_as_percentage) {
    valueDisplay = `${rounded} %`;
  } else if (!thisMetric.denominator_unit) {
    valueDisplay = `${rounded} ${thisMetric.numerator_unit}`;
  } else {
    valueDisplay = `${rounded} ${thisMetric.numerator_unit} / ${thisMetric.denominator_unit}`;
  }

  // Default color coding logic
  const warningLimit =
    thisMetric.warning_limit ??
    (thisMetric.larger_is_better ? thisScore.score * 2 : thisScore.score / 2);
  const criticalLimit =
    thisMetric.critical_limit ??
    (thisMetric.larger_is_better ? thisScore.score * 2 : thisScore.score / 2);
  const limitRange = Math.abs(warningLimit - criticalLimit);

  let qualityScale = 0; // 0 is "good";
  if (thisMetric.larger_is_better) {
    if (thisScore.score <= criticalLimit) {
      qualityScale = 1;
    } else if (thisScore.score <= warningLimit) {
      qualityScale = (warningLimit - thisScore.score) / limitRange;
    }
  } else {
    if (thisScore.score >= criticalLimit) {
      qualityScale = 1;
    } else if (thisScore.score >= warningLimit) {
      qualityScale = (thisScore.score - warningLimit) / limitRange;
    }
  }
  const colorScaleIndex = Math.round(
    (scoreColorScale.length - 1) * Math.max(Math.min(qualityScale, 1), 0),
  );
  colors = scoreColorScale[colorScaleIndex];

  return (
    <TableCell>
      <Tooltip title={datasetDisplay}>
        <StyledChip
          label={valueDisplay}
          style={{ backgroundColor: colors.background, color: "#000000" }}
        />
      </Tooltip>
      {thisScore.num_samples < thisMetric.min_num_samples && (
        <Tooltip
          title={`does not meet minimum number of samples: ${thisMetric.min_num_samples}`}
        >
          <StyledChip label={<Warning fontSize="small" />} />
        </Tooltip>
      )}
    </TableCell>
  );
};
