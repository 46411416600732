import { useState } from "react";
import {
  Box,
  Button,
  Card,
  Chip,
  Grid,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { AddCircleOutline } from "@material-ui/icons";
import { SearchBar } from "src/components/filters";
import { Resource, useResourceNav } from "src/components/Resource";
import {
  ChipColumn,
  createTable,
  EllipsisColumn,
  TableOptionals,
  TextColumn,
} from "src/components/table";
import { usePagination, like, uuidIsValid } from "src/resources/Utils";
import {
  Breadcrumb,
  Breadcrumbs,
  ShowResourceView,
  TableView,
  TableViewHeader,
} from "src/Layout";
import {
  Deliveries_Artifacts as Artifacts,
  Deliveries_Artifacts_Bool_Exp,
  Deliveries_Microsoft_Whql_Submissions as WHQLSubmissions,
  Deliveries_Microsoft_Whql_Submissions_Bool_Exp as WHQLSubmissions_Bool_Exp,
  Order_By,
  useAllMicrosoftWhqlSubmissionsQuery,
  useCreateMicrosoftWhqlSubmissionMutation,
  useMicrosoftWhqlSubmissionQuery,
  useUpdateMicrosoftWhqlSubmissionMutation,
  useAllArtifactsQuery,
} from "src/generated/asgard/graphql";
import {
  AllMicrosoftShippingLabelsTable,
  useMicrosoftShippingLabelNav,
} from "./microsoft_shipping_label";
import * as Yup from "yup";
import { FormAction, ResourceForm } from "src/components/ResourceForm";
import {
  DialogSelect,
  FormContainer,
  FormHeader,
  TextField,
} from "src/components/Form";

// Config table columns from DriverVersion fields
export const MicrosoftWHQLSubmissionTable = createTable<WHQLSubmissions>()({
  keys: (microsoft_whql_submission) =>
    `${microsoft_whql_submission.id}-${microsoft_whql_submission.microsoft_partner_center_submission_name}`,
  title: "Package WHQL submissions",
  headers: {
    id: { display: "ID" },
    microsoft_partner_center_product_name: {
      display: "Partner Center Product Name",
    },
    microsoft_partner_center_submission_name: {
      display: "Partner Center Submission Name",
    },
    microsoft_partner_center_product_id: {
      display: "Partner Center Product ID",
    },
    microsoft_partner_center_submission_id: {
      display: "Partner Center Submission ID",
    },
    signatures: { display: "WHQL OS signatures" },
    microsoft_partner_center_submission_url: {
      display: "Partner Center Submission Link",
    },
    created_at: { display: "Created at" },
  },
  columns: (microsoft_whql_submission) => ({
    id: <EllipsisColumn value={microsoft_whql_submission.id || ""} />,
    microsoft_partner_center_product_name: (
      <TextColumn
        value={
          microsoft_whql_submission.microsoft_partner_center_product_name ||
          "N/A"
        }
      />
    ),
    microsoft_partner_center_submission_name: (
      <TextColumn
        value={
          microsoft_whql_submission.microsoft_partner_center_submission_name ||
          "N/A"
        }
      />
    ),
    microsoft_partner_center_product_id: (
      <TextColumn
        value={
          microsoft_whql_submission.microsoft_partner_center_product_id || "N/A"
        }
      />
    ),
    microsoft_partner_center_submission_id: (
      <TextColumn
        value={
          microsoft_whql_submission.microsoft_partner_center_submission_id ||
          "N/A"
        }
      />
    ),
    signatures: microsoft_whql_submission.artifact?.artifact_operating_systems &&
    microsoft_whql_submission.artifact.artifact_operating_systems.length >
      0 ? (<ChipColumn
      chips={
        microsoft_whql_submission.artifact.artifact_operating_systems
        .slice()
        .sort((a, b) => {
          const osA = a.operating_system?.fullname || "";
          const osB = b.operating_system?.fullname || "";
          return osA.localeCompare(osB); // Sort alphabetically by fullname
        }).map((artifact_os) => ({ label: artifact_os.operating_system?.fullname ?? "" })) ?? []
      }
    />) : (
      <TextColumn value="None" />
    ),
    microsoft_partner_center_submission_url:
      microsoft_whql_submission.microsoft_partner_center_product_id ? (
        <Button
          variant="contained"
          color="primary"
          href={`https://partner.microsoft.com/en-us/dashboard/hardware/driver/${microsoft_whql_submission.microsoft_partner_center_product_id}`}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            fontSize: "0.8rem",
            padding: "4px 8px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          View in Partner Center
        </Button>
      ) : (
        <span>N/A</span>
      ),
    created_at: (
      <TextColumn
        value={
          microsoft_whql_submission.created_at
            ? new Intl.DateTimeFormat("en-US", {
                year: "numeric",
                month: "short",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              }).format(new Date(microsoft_whql_submission.created_at))
            : "N/A"
        }
      />
    ),
  }),
});

// Define a new table component for DriverVersions
type AllMicrosoftWHQLSubmissionsTableProps = TableOptionals<
  typeof MicrosoftWHQLSubmissionTable
> & {
  where?: WHQLSubmissions_Bool_Exp[];
};

export const AllMicrosoftWHQLSubmissionsTable = (
  props: AllMicrosoftWHQLSubmissionsTableProps,
) => {
  const [pageVars, pageController] = usePagination();
  const [search, setSearch] = useState("");
  const searchFilters: WHQLSubmissions_Bool_Exp[] = [];
  if (uuidIsValid(search)) {
    searchFilters.push({ id: { _eq: search } });
  } else {
    // Add search terms for individual fields.
    const term = like(search);
    searchFilters.push({
      microsoft_partner_center_product_name: { _ilike: term },
    });
    searchFilters.push({
      microsoft_partner_center_submission_name: { _ilike: term },
    });
  }
  const { data } = useAllMicrosoftWhqlSubmissionsQuery({
    variables: {
      ...pageVars,
      where: { _and: [{ _and: props.where }, { _or: searchFilters }] },
      order_by: [{ created_at: Order_By.Desc }],
    },
    fetchPolicy: "network-only",
  });

  return (
    <MicrosoftWHQLSubmissionTable
      {...props}
      {...pageController}
      total={data?.microsoft_whql_submissions_aggregate?.aggregate?.count}
      data={data?.microsoft_whql_submissions}
      tools={
        <Grid item xs={12}>
          <SearchBar onChange={setSearch} />
        </Grid>
      }
    />
  );
};

// Define content to display in the main MicrosoftWHQLSubmission resource page
type MicrosoftWHQLSubmissionIndexProps = {
  onAddNew: () => void;
} & TableOptionals<typeof MicrosoftWHQLSubmissionTable>;

// Config table columns from Model fields
export const ArtifactTable = createTable<Artifacts>()({
  keys: (model) => model.id ?? "",
  title: "Artifacts",
  headers: {
    id: { display: "ID" },
    package_name: { display: "Package name" },
    package_version: { display: "Package version" },
    signatures: { display: "Signatures" },
  },
  columns: (artifact) => ({
    id: <EllipsisColumn value={artifact.id} />,
    package_name: <TextColumn value={artifact.package?.name} />,
    package_version: <TextColumn value={artifact.package?.version} />,
    signatures: (
      <TextColumn
        value={
          artifact.artifact_operating_systems?.length
            ? artifact.artifact_operating_systems
                .map((os) => os.operating_system?.fullname || "Unknown OS")
                .sort()
                .join(", ")
            : "None"
        }
      />
    ),
  }),
});

// Define a new table component for Models
type AllArtifactsTableProps = TableOptionals<typeof ArtifactTable> & {
  where?: Deliveries_Artifacts_Bool_Exp[];
};

export const AllArtifactsTable = (props: AllArtifactsTableProps) => {
  const [pageVars, pageController] = usePagination();
  const [search, setSearch] = useState("");
  const searchFilters: Deliveries_Artifacts_Bool_Exp[] = [];
  if (uuidIsValid(search)) {
    searchFilters.push({ id: { _eq: search } });
  }
  // Add search terms for individual fields.
  searchFilters.push({ package: { name: { _ilike: search } } });
  searchFilters.push({ package: { version: { _ilike: search } } });
  const { data } = useAllArtifactsQuery({
    variables: {
      ...pageVars,
      where: { _and: [{ _and: props.where }] },
    },
  });

  return (
    <ArtifactTable
      {...props}
      {...pageController}
      total={data?.artifacts_aggregate?.aggregate?.count}
      data={data?.artifacts}
      tools={
        <Grid item xs={12}>
          <SearchBar onChange={setSearch} />
        </Grid>
      }
    />
  );
};

export const MicrosoftWHQLSubmissionIndex = (
  props: MicrosoftWHQLSubmissionIndexProps,
) => {
  return (
    <TableView>
      <TableViewHeader
        title={<Typography variant="h5">WHQL Submissions</Typography>}
      >
        <Button
          onClick={props.onAddNew}
          variant="contained"
          color="primary"
          startIcon={<AddCircleOutline />}
          disableElevation
        >
          New WHQL Submission
        </Button>
      </TableViewHeader>
      <Card>
        <AllMicrosoftWHQLSubmissionsTable {...props} selectable="none" />
      </Card>
    </TableView>
  );
};

const packageSchema = Yup.object({
  name: Yup.string().required(),
  version: Yup.string().required(),
  name_version: Yup.string().nullable(),
});

const artifactSchema = Yup.object({
  id: Yup.string().required(),
  package: packageSchema.required("required"),
});

const microsoftWHQLSubmissionSchema = Yup.object({
  microsoft_partner_center_product_name: Yup.string().required("required"),
  microsoft_partner_center_submission_name: Yup.string().required("required"),
  microsoft_partner_center_product_id: Yup.number().required("required"),
  microsoft_partner_center_submission_id: Yup.number().required("required"),
  microsoft_partner_center_submission_type: Yup.string().required("required"),
  artifact: artifactSchema.required("required"),
}).required();

export type MicrosoftWHQLSubmissionFormProps = {
  action: FormAction;
  microsoft_whql_submission?: { id: string };
  onSuccess?: (id: string) => void;
};

export const MicrosoftWHQLSubmissionForm = (
  props: MicrosoftWHQLSubmissionFormProps,
) => {
  const [createMicrosoftWhqlSubmission] =
    useCreateMicrosoftWhqlSubmissionMutation();
  const [updateMicrosoftWhqlSubmission] =
    useUpdateMicrosoftWhqlSubmissionMutation();
  const allMicrosoftWHQLSubmissions = useAllMicrosoftWhqlSubmissionsQuery({
    variables: {
      where: {
        id: {
          _nin: props.microsoft_whql_submission?.id
            ? [props.microsoft_whql_submission.id]
            : [],
        },
      },
    },
    fetchPolicy: "network-only",
  });

  const existingMicrosoftWHQLSubmission = useMicrosoftWhqlSubmissionQuery({
    variables: { id: props.microsoft_whql_submission?.id ?? "" },
    fetchPolicy: "network-only",
    skip: !props.microsoft_whql_submission,
  });

  return (
    <ResourceForm
      action={props.action}
      schema={microsoftWHQLSubmissionSchema}
      resourceToUpdate={
        existingMicrosoftWHQLSubmission.data?.microsoft_whql_submission
      }
      transform={(microsoft_whql_submission) => ({
        microsoft_partner_center_product_name:
          microsoft_whql_submission.microsoft_partner_center_product_name,
        microsoft_partner_center_submission_name:
          microsoft_whql_submission.microsoft_partner_center_submission_name ??
          "",
        microsoft_partner_center_product_id:
          microsoft_whql_submission.microsoft_partner_center_product_id,
        microsoft_partner_center_submission_id:
          microsoft_whql_submission.microsoft_partner_center_submission_id,
        microsoft_partner_center_submission_type:
          microsoft_whql_submission.microsoft_partner_center_submission_type,
        artifact: {
          id: microsoft_whql_submission.artifact.id,
          package: {
            name: microsoft_whql_submission.artifact.package.name,
            version: microsoft_whql_submission.artifact.package.version ?? "",
            name_version:
              microsoft_whql_submission.artifact.package.name +
              " - " +
              microsoft_whql_submission.artifact.package.version,
          },
        },
      })}
      initialValues={{
        microsoft_partner_center_product_name: "",
        microsoft_partner_center_submission_name: "",
        microsoft_partner_center_product_id: 10000000000000000,
        microsoft_partner_center_submission_id: 1000000000000000000,
        microsoft_partner_center_submission_type: "",
        artifact: {
          id: "",
          package: {
            name: "",
            version: "",
            name_version: "",
          },
        },
      }}
      customValidator={(values, errors) => {
        for (let existing of allMicrosoftWHQLSubmissions.data
          ?.microsoft_whql_submissions ?? []) {
          if (
            existing.microsoft_partner_center_product_id ===
              values.microsoft_partner_center_product_id &&
            existing.microsoft_partner_center_submission_id ===
              values.microsoft_partner_center_submission_id
          ) {
            errors.microsoft_partner_center_product_id = `These product ID and submission ID already exists`;
          }
          if (errors.microsoft_partner_center_product_id) {
            break;
          }
        }
      }}
      onUpdate={async (values) => {
        const { artifact, ...columns } = values;

        const result = await (async () => {
          if (props.microsoft_whql_submission?.id)
            return await updateMicrosoftWhqlSubmission({
              variables: {
                id: props.microsoft_whql_submission.id,
                columns: { ...columns },
              },
            });
        })();
        props.onSuccess &&
          props.onSuccess(result?.data?.microsoft_whql_submission?.id ?? "");
      }}
      onInsert={async (values) => {
        const { artifact, ...columns } = values;

        // Perform initial insert without artifact_operating_systems
        const result = await (async () => {
          return await createMicrosoftWhqlSubmission({
            variables: {
              input: {
                ...columns,
                artifact_id: artifact.id,
              },
            },
          });
        })();

        // Get the newly created submission's ID
        const newSubmissionId = result.data?.microsoft_whql_submission?.id;

        // Final callback on success
        props.onSuccess && props.onSuccess(newSubmissionId ?? "");
      }}
      render={({ formik, path }) => (
        <>
          <FormHeader>
            {props.microsoft_whql_submission
              ? "Update WHQL Submission"
              : "Create New WHQL Submission"}
          </FormHeader>
          <FormContainer>
            <DialogSelect
              bp={{ xs: 6 }}
              multiple={false}
              onReset={() =>
                formik.setValues({
                  ...formik.values,
                  artifact: formik.initialValues.artifact,
                })
              }
              name={path.artifact.package.name_version._}
              label="Package Artifact"
              content={(close) => (
                <>
                  <AllArtifactsTable
                    selectable="single"
                    onSelect={(artifact) => {
                      formik.setValues({
                        ...formik.values,
                        artifact: {
                          id: artifact.id ?? "",
                          package: {
                            name: artifact.package?.name ?? "",
                            version: artifact.package?.version ?? "",
                            name_version:
                              artifact.package?.name +
                              " - " +
                              artifact.package?.version,
                          },
                        },
                      });
                      close();
                    }}
                  />
                </>
              )}
            />
            <TextField
              bp={{ xs: 12, sm: 8 }}
              name={path.microsoft_partner_center_product_name?._}
              label="Microsoft Partner Center Product Name"
            />
            <TextField
              bp={{ xs: 12, sm: 8 }}
              name={path.microsoft_partner_center_submission_name?._}
              label="Microsoft Partner Center Submission Name"
            />
            <TextField
              bp={{ xs: 12, sm: 8 }}
              name={path.microsoft_partner_center_product_id._}
              label="Microsoft Partner Center Product ID"
            />
            <TextField
              bp={{ xs: 12, sm: 8 }}
              name={path.microsoft_partner_center_submission_id._}
              label="Microsoft Partner Center Submission ID"
            />
            <TextField
              select
              bp={{ xs: 12, sm: 8 }}
              name={path.microsoft_partner_center_submission_type._}
              label="Microsoft Partner Center Submission Type"
              defaultValue="initial" // Set a default value if desired
            >
              <MenuItem value="Initial">Initial</MenuItem>
              <MenuItem value="Derived">Derived</MenuItem>
            </TextField>
          </FormContainer>
        </>
      )}
    />
  );
};

// Create a detailed 'show' page.
type MicrosoftWHQLSubmissionShowProps = {
  id: string;
  onEditAction?: (item: DeepPartial<WHQLSubmissions>) => void;
};

const MicrosoftWHQLSubmissionShow = (
  props: MicrosoftWHQLSubmissionShowProps,
) => {
  const microsoftShippingLabelNav = useMicrosoftShippingLabelNav();
  const microsoftWHQLSubmissionNav = useMicrosoftWHQLSubmissionNav();

  const microsoftWHQLSubmissionQuery = useMicrosoftWhqlSubmissionQuery({
    variables: { id: props.id },
    fetchPolicy: "network-only",
  });
  const microsoft_whql_submission =
    microsoftWHQLSubmissionQuery.data?.microsoft_whql_submission;
  if (!microsoft_whql_submission) return null;

  const properties = [
    {
      label: "Partner Center Product Name",
      value:
        microsoft_whql_submission.microsoft_partner_center_product_name ||
        "N/A",
    },
    {
      label: "Partner Center Submission Name",
      value:
        microsoft_whql_submission.microsoft_partner_center_submission_name ||
        "N/A",
    },
    {
      label: "Partner Center Product ID",
      value:
        microsoft_whql_submission.microsoft_partner_center_product_id?.toString() ||
        "N/A",
    },
    {
      label: "Partner Center Submission ID",
      value:
        microsoft_whql_submission.microsoft_partner_center_submission_id?.toString() ||
        "N/A",
    },
    {
      label: "Partner Center Submission Type",
      value:
        microsoft_whql_submission.microsoft_partner_center_submission_type ||
        "N/A",
    },
    {
      label: "Signatures",
      value: microsoft_whql_submission.artifact?.artifact_operating_systems
        ?.length ? (
        <Box display="flex" flexWrap="wrap">
          {microsoft_whql_submission.artifact.artifact_operating_systems
            .slice()
            .sort((a, b) => {
              const osA = a.operating_system?.fullname || "";
              const osB = b.operating_system?.fullname || "";
              return osA.localeCompare(osB);
            })
            .map((os, index) => (
              <Chip
                key={index}
                label={os.operating_system?.fullname || "Unknown OS"}
                variant="outlined"
                style={{ margin: 1 }}
              />
            ))}
        </Box>
      ) : (
        "None"
      ),
    },
    {
      label: "Created At",
      value: (
        <TextColumn
          value={
            microsoft_whql_submission.created_at
              ? new Intl.DateTimeFormat("en-US", {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  hour12: true,
                }).format(new Date(microsoft_whql_submission.created_at))
              : "N/A"
          }
        />
      ),
    },
  ];

  return (
    <ShowResourceView
      title={
        microsoft_whql_submission.microsoft_partner_center_product_name +
          " - " +
          microsoft_whql_submission.microsoft_partner_center_submission_name ||
        ""
      }
      breadcrumbs={
        <Breadcrumbs>
          <Breadcrumb
            label="WHQL Submissions"
            onClick={() => microsoftWHQLSubmissionNav.list()}
          />
          <Breadcrumb
            label={
              microsoft_whql_submission.microsoft_partner_center_product_name +
                " - " +
                microsoft_whql_submission.microsoft_partner_center_submission_name ||
              ""
            }
          />
        </Breadcrumbs>
      }
      onEditAction={() =>
        props.onEditAction && props.onEditAction(microsoft_whql_submission)
      }
    >
      {/* Section 1: WHQL Submission Info */}
      <Box mb={3}>
        <Typography variant="h6" gutterBottom>
          WHQL Submission Infos
        </Typography>
        <Grid container spacing={2}>
          {properties.map((prop, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <Typography variant="body2" color="textSecondary">
                {prop.label}:
              </Typography>
              <Typography variant="body1">{prop.value}</Typography>
            </Grid>
          ))}
        </Grid>

        {microsoft_whql_submission.microsoft_partner_center_product_id && (
          <Box mt={2}>
            <Button
              variant="contained"
              color="primary"
              href={`https://partner.microsoft.com/en-us/dashboard/hardware/driver/${microsoft_whql_submission.microsoft_partner_center_product_id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              View Submission In Partner Center
            </Button>
          </Box>
        )}
      </Box>

      {/* Section 2: Shipping Labels Associated */}
      <Box mb={3}>
        <Typography variant="h6">Shipping Labels Associated</Typography>
        <AllMicrosoftShippingLabelsTable
          showUrl={(item) =>
            item.id && microsoftShippingLabelNav.showUrl(item.id)
          }
          where={[{ microsoft_whql_submission_id: { _eq: props.id } }]}
          selectable="none"
        />
      </Box>
    </ShowResourceView>
  );
};

// Finally, combine into full resource UI.
const path = "microsoftWHQLsubmissions";
export const useMicrosoftWHQLSubmissionNav = () => useResourceNav(path);
export const MicrosoftWHQLSubmissionResource = () => (
  <Resource
    path={path}
    list={(nav) => (
      <MicrosoftWHQLSubmissionIndex onAddNew={() => nav.create()} />
    )}
    show={(nav, id) => (
      <MicrosoftWHQLSubmissionShow
        id={id ?? ""}
        onEditAction={(item) => item.id && nav.edit(item.id)}
      />
    )}
    edit={(nav, id) => (
      <MicrosoftWHQLSubmissionForm
        action="update"
        onSuccess={(id) => nav.show(id)}
        microsoft_whql_submission={{ id: id ?? "" }}
      />
    )}
    create={(nav) => (
      <MicrosoftWHQLSubmissionForm
        action="insert"
        onSuccess={(createdId) => nav.show(createdId)}
      />
    )}
  />
);
